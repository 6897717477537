<template>
	<div class="orderDetailpage" v-if="orderInfo">
		<div>
			<div style="color: #000000;font-size: 18px;border-bottom: 1px solid #ddd;padding-bottom: 10px;margin-bottom: 10px;font-weight: bold;">详情信息</div>
			<div style="display: flex;justify-content: space-between;width: 70%;">
				<div class="mgTop" style="width: 400px;">
					<div style="font-size: 16px;font-weight: bold;">订单详情</div>
					<div class="mgTop">订单类型：{{orderInfo.orderByVO.orderType | orderTypeFilter}}</div>
					<div class="mgTop">订单编号：{{orderInfo.orderByVO.orderNo}}</div>
					<div class="mgTop">交易流水号：{{orderInfo.orderByVO.paymentNo}}</div>
					<div v-if="orderInfo.orderByVO.orderStatus==8" class="mgTop">订单状态：买家付款中</div>
					<div v-else class="mgTop">订单状态：{{stateList[orderInfo.orderByVO.orderStatus-1]}}</div>
					<div class="mgTop">下单时间：{{orderInfo.orderByVO.createTime}}</div>
					<div class="mgTop" v-if="orderInfo.orderByVO.orderType != 6">配送方式：{{orderInfo.orderByVO.deliveryMethod==1 ? '快递发货':''}}</div>
					<div class="mgTop">买家留言：{{orderInfo.orderByVO.remark}}</div>
				</div>
				<div class="mgTop" style="width: 300px;margin-left: 50px;" v-if="orderInfo.orderByVO.orderType != 6">
					<div style="font-size: 16px;font-weight: bold;display: flex;align-items: center;">收货人信息 <div style="color: #007AFF;font-size: 14px;margin-left: 10px;" @click="addressDialogShow()" v-if="orderInfo.orderByVO.orderStatus==2">修改地址</div></div>
					<div class="mgTop">收货人：{{orderInfo.orderByVO.receiveName}}</div>
					<div class="mgTop">手机号码：{{orderInfo.orderByVO.receivePhone}}</div>
					<div class="mgTop">地址：{{orderInfo.orderByVO.receiveProvince}}{{orderInfo.orderByVO.receiveCity}}{{orderInfo.orderByVO.receiveArea}}{{orderInfo.orderByVO.receiveDetailAddress}}</div>
				</div>
				<div class="mgTop" style="width: 300px;margin-left: 50px;">
					<div style="font-size: 16px;font-weight: bold;">订单详情</div>
					<div class="mgTop" v-if="orderInfo.orderByVO.anotherPay">支付方式：好友代付</div>
					<div class="mgTop" v-if="!orderInfo.orderByVO.anotherPay&&orderInfo.orderByVO.paymentChannel>0">支付方式：{{orderInfo.orderByVO.paymentChannel==2?'通联支付':(orderInfo.orderByVO.paymentChannel==3?'通商云':'微信支付')}}</div>
					<div class="mgTop">商品总额：¥{{orderInfo.orderByVO.orginalAmount||0}}</div>
					<!-- <div class="mgTop">优惠：¥{{orderInfo.orderByVO.discountAmount||0}}</div> -->

					<div class="mgTop">优惠券：-¥{{orderInfo.orderByVO.allDiscountAmount||0}}</div>
					<div class="mgTop">{{orderInfo.orderByVO.buyerLevel !=0 ? '权益优惠':'新客优惠'}}：-¥{{orderInfo.orderByVO.allProfitDeductAmount||0}}</div>
					<div class="mgTop">增值服务：¥{{orderInfo.orderByVO.allFreightRiskAmountSum||0}}</div>

					<div class="mgTop">运费：¥{{orderInfo.orderByVO.freightAmount||0}}</div>
					<div v-if="orderInfo.orderByVO.orderType == 12" class="mgTop">库存抵扣：¥{{orderInfo.orderByVO.stockDeductAmount||0}}</div>
					<div class="mgTop">实收金额：¥{{orderInfo.orderByVO.paymentAmount?orderInfo.orderByVO.paymentAmount:0}}</div>
				</div>

				<div class="mgTop" style="width: 300px;margin-left: 50px;">
					<div style="font-size: 16px;font-weight: bold;">买家信息</div>
					<div class="mgTop">买家信息：{{orderInfo.userVO.nickName}}</div>
					<div class="mgTop">买家手机号：{{orderInfo.userVO.phoneNumber}}</div>
					<!-- <div class="mgTop">会员级别：{{orderInfo.userVO.vipValue}}</div> -->
					<div class="mgTop">会员类型:{{orderInfo.userVO.pusherLevel | getUserLevelName}}</div>
					<!-- <div class="mgTop">推手：{{orderInfo.userVO.pusherValue||'--'}}</div> -->
				</div>
				<div v-if="orderInfo.orderByVO.anotherPay" class="mgTop" style="width: 300px;margin-left: 50px;">
					<div style="font-size: 16px;font-weight: bold;">代付信息</div>
					<div class="mgTop">代付人：{{orderInfo.orderByVO.nickName}}</div>
					<div class="mgTop">手机号码：{{orderInfo.orderByVO.phoneNum}}</div>
				</div>
			</div>
		</div>
		<div v-if="orderInfo.orderByVO.userIdCardInfo">
			<div style="color: #000000;font-size: 18px;border-bottom: 1px solid #ddd;padding-bottom: 10px;margin-bottom: 10px;margin-top: 40px;font-weight: bold;">推手信息</div>
			<div style="display: flex;justify-content: space-between;width: 70%;">
				<!-- <div class="mgTop" style="width: 300px;">
					<div style="font-size: 16px;font-weight: bold;">买家信息</div>
					<div class="mgTop">买家信息：{{orderInfo.userVO.nickName}}</div>
					<div class="mgTop">会员级别：{{orderInfo.userVO.vipValue}}</div>
					<div class="mgTop">推手：{{orderInfo.userVO.pusherValue||'--'}}</div>
				</div> -->
				<!-- <div class="mgTop" style="width: 300px;margin-left: 50rpx;">
					<div style="font-size: 16px;font-weight: bold;">收益与提成</div>
					<div class="mgTop">推手：{{orderInfo.invitendVO.invitendName||'--'}}</div>
					<div class="mgTop">自营零售收益：¥0</div>
					<div class="mgTop">直推销售收益：¥0</div>
				</div> -->
				<div class="mgTop" style="width: 300px;margin-left: 50rpx;">
					<div v-if="orderInfo.orderByVO.userIdCardInfo">
						<div style="font-size: 16px;font-weight: bold;">实名购物信息 <i class="el-icon-edit-outline" @click="editRoleInfo"></i></div>
						<div class="mgTop">姓名：{{orderInfo.orderByVO.userIdCardInfo.realName}}</div>
						<div class="mgTop">身份证：{{orderInfo.orderByVO.userIdCardInfo.idCardNo}}</div>
						<div style="display: flex;align-items: center;margin-top: 30px;">
							<img :src="orderInfo.orderByVO.userIdCardInfo.frontImgUrl" alt="" style="width: 200px;height: 112px;">
							<img :src="orderInfo.orderByVO.userIdCardInfo.reverseImgUrl" alt="" style="width: 200px;height: 112px;margin-left: 20px;">
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 表格 -->
		<div style="color: #000000;font-size: 18px;margin-bottom: 20px;font-weight: bold;margin-top: 30px;">订单商品</div>
		<div class="table-container" style="margin-top:20px">
			<el-table :data="groupData" :default-expand-all="true" style="width: 1700px;">
				<el-table-column type="expand">
					<template slot-scope="scope">
						<div style="width: 1700px;">
							<div style="width: 1700px;display: flex;align-items: center;" v-for="(item,index) in scope.row.list" :key="index">
								<div style="">
									<div style="display: flex;align-items: center;margin-top: 15px;width: 300px;">
										<img :src="imgUrl+item.skuImgUrl" alt="" style="width: 50px;height: 50px;">
										<div style="height: 60px;margin-left: 10px;display: flex;flex-direction: column;justify-content: space-between;">
											<div style="overflow: hidden;white-space: nowrap;width: 180px;text-overflow: ellipsis;">{{item.goodsName}}</div>
											<div>{{item.goodsCode}}</div>
                                            <el-tag v-if='item.ledgerAccountType == 1' effect="dark" size='mini' style="width:56px;">A类商品</el-tag>
                                            <el-tag v-if='item.ledgerAccountType == 2' type="success" effect="dark" size='mini' style="width:56px;">B类商品</el-tag>
                                            <el-tag v-if='item.ledgerAccountType == 3' type="warning" effect="dark" size='mini' style="width:56px;">C类商品</el-tag>
										</div>
									</div>
                                    <div style="margin-top:10px">买家留言：{{item.userLeaveMessage}}</div>
								</div>
								<div style="width: 200px;padding:0 10px;">{{item.specValues?item.specValues:'默认规格'}}</div>
								<div style="width: 200px;">{{item.unitPrice||0}}</div>
								<div style="width: 150px;">{{item.profitDeductAmount||0}}</div>
								<div style="width: 150px;">{{item.quantity||0}}</div>
								<div style="width: 150px;">{{item.xjMoney}}</div>
								<div style="width: 150px;">{{scope.row.orderDetailAmoutVO.sumProfitDeductAmount||0}}元/件</div>
                                <div style="width: 150px;margin-left:20px;">¥{{item.freightRiskAmount}}</div>
								<div style="width: 150px;" >
									<div v-if="orderInfo.orderByVO.orderStatus!=1 && orderInfo.orderByVO.orderStatus!=2 && (item.afterSaleStatus==-1 || item.afterSaleStatus==2) && orderInfo.orderByVO.orderStatus!=6" style="border: 1px solid #f00;padding: 4px 0;width: 90px;text-align: center;color: #f00;" @click="submitBack(item)">主动退款</div>
									<!-- <div v-else style="border: 1px solid #ddd;padding: 4px 0;width: 90px;text-align: center;color: #999;">主动退款</div> -->
								</div>
							</div>
							<div style="padding-top: 20px;width: 1620px;">
								<div style="display: flex;flex-direction: column;align-items: flex-end;padding-right: 70px;">
									<div>总金额：¥{{scope.row.orderDetailAmoutVO.sumGoodsAmount||0}}</div>
									<div style="margin-top: 10px;">优惠券：-¥{{scope.row.orderDetailAmoutVO.sumCouponReduceAmount||0}}</div>
									<div style="margin-top: 10px;">{{orderInfo.orderByVO.buyerLevel !=0 ? '权益优惠':'新客优惠'}}：-¥{{scope.row.orderDetailAmoutVO.sumProfitDeductAmount||0}}</div>
									<div style="margin-top: 10px;">运费：¥{{scope.row.orderDetailAmoutVO.sumFreightAmount||0}}</div>
									<div style="margin-top: 10px;">增值服务：¥{{scope.row.freightRiskAmountSum||0}}</div>
								</div>
								<div style="border-top: 1px solid #eee;margin-top: 15px;padding-top: 15px;display: flex;flex-direction: column;align-items: flex-end;padding-right: 70px;">
									<div>订单合计：¥{{scope.row.orderDetailAmoutVO.countAmount||0}}</div>
									<!-- <div style="margin-top: 10px;">返现退款：¥0</div> -->
								</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="商品" width="300px">
					<template slot-scope="scope">
						<div>包裹{{scope.row.num}}</div>
					</template>
				</el-table-column>
				<el-table-column label="规格" width="200px">
					<template slot-scope="scope">
					</template>
				</el-table-column>
				<el-table-column label="商品价格" width="200px">
					<template slot-scope="scope">
						<div></div>
					</template>
				</el-table-column>
				<el-table-column label="优惠金额" width="150px">
					<template slot-scope="scope">
						<div></div>
					</template>
				</el-table-column>
				<el-table-column label="购买数量" width="150px"></el-table-column>
				<el-table-column label="小计(元)" width="150px"></el-table-column>
				<!-- <el-table-column label="返现退款" width="150px"></el-table-column> -->
				<el-table-column label="收益" width="150px"></el-table-column>
				<!-- 运费保障卡 -->
                <el-table-column label="增值服务" width="150px"></el-table-column>
				<el-table-column label="退款信息" width="150">
					<template slot-scope="scope">
						<!-- orderInfo.orderByVO.orderStatus>=3 -->
						<div style="color: #007AFF;" v-if="scope.row.logisticsList && scope.row.logisticsList.length>0" @click="expressClick(scope.row)">物流跟踪</div>
					</template>
				</el-table-column>
			</el-table>
			<div style="display: flex;justify-content: flex-end;margin: 20px 150px 20px 0;" v-if="orderInfo.orderByVO.orderStatus==2">
				<div style="width: 150px;">
					<div style="border: 1px solid #f00;padding: 4px 0;width: 90px;text-align: center;color: #f00;" @click="submitBackAll()">整单退款</div>
				</div>
			</div>
		</div>

		<div style="color: #000000;font-size: 18px;margin-bottom: 20px;font-weight: bold;margin-top: 30px;">
            订单备注
            <span style="margin-left:10px;color:#007aff;font-size:14px;" @click="ordermarkDialogVisible = true;">添加备注</span>
        </div>
		<el-table :data="orderRemark" style="width: 1700px;">
			<el-table-column prop="remark" label="备注内容"></el-table-column>
			<el-table-column prop="adminName" label="操作人"></el-table-column>
			<el-table-column prop="createTime" label="操作时间"></el-table-column>
		</el-table>
		<div style="color: #000000;font-size: 18px;margin-bottom: 20px;font-weight: bold;margin-top: 30px;">包裹轨迹</div>
		<el-tabs v-model="activeName" @tab-click="handlePackageClick">
			<el-tab-pane v-for="(item,index) in groupData" :key="index" :label="packageDesc[index]" :name="index+''"></el-tab-pane>
		</el-tabs>
		<el-table :data="packageRecord" style="width: 1700px;">
			<el-table-column prop="orderValue" label="操作类型"></el-table-column>
			<el-table-column prop="userName" label="操作人"></el-table-column>
			<el-table-column prop="createTime" label="操作时间"></el-table-column>
		</el-table>

		<el-dialog title="物流信息" :visible.sync="expressVisible" width="800px">
			<div style="height: 500px;overflow: auto;width: 700px;">
				<el-tabs v-if="expressList.length" v-model="activeTab" @tab-click="handleClick">
					<el-tab-pane v-for="(item,index) in expressList" :key="index" :label="item.label" :name="item.value">
						<div>
							<div>物流公司：{{expressDetail.logisticsCompany || item.logisticsCompany}}</div>
							<div style="margin-top: 10px;">物流单号：{{expressDetail.expressCode || item.expressNo}}</div>
							<div style="margin-top: 10px;">发货时间：{{item.modifyTime}}</div>
							<div style="margin-top: 10px;margin-bottom: 20px;">物流跟踪：</div>
							<el-timeline>
								<el-timeline-item v-for="(item, index) in expressDetailList" :key="index" :timestamp="item.time" placement="top">
									<el-card>
										<p style="word-wrap:break-word;">{{item.context}}</p>
									</el-card>
								</el-timeline-item>
							</el-timeline>
						</div>
					</el-tab-pane>
				</el-tabs>
				<div v-else style="text-align: center;margin-top: 200px;color: #999;">暂无物流信息</div>
			</div>
		</el-dialog>
		<el-dialog title="主动退款" :visible.sync="backVisible" width="450px">
			<div>
				<div>
					若订单已经提交给仓库进行发货，主动退款前，请告知相关发货人员，避免商品多发。
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button @click="backVisible=false">关 闭</el-button>
					<el-button type="primary" @click="continueBack" style="margin-left: 30px;">继续退款</el-button>
				</span>
			</div>
		</el-dialog>
		<el-dialog title="整单退款" :visible.sync="backAllVisible" width="450px">
			<div>
				<div>
					确定要整单退款吗？
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button @click="backAllVisible=false">关 闭</el-button>
					<el-button type="primary" @click="continueBackAll" style="margin-left: 30px;">继续退款</el-button>
				</span>
			</div>
		</el-dialog>

		<el-dialog title="修改购物实名信息" :visible.sync="roleVisible" width="800px" @close="roleVisible=false">
			<div>
				<div>
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm">
						<el-form-item label="姓名:" label-width="100px" prop="name">
							<el-input v-model="ruleForm.name" placeholder="请输入姓名" clearable style="width:460px"></el-input>
						</el-form-item>
						<el-form-item label="身份证号:" label-width="100px" prop="idcard">
							<el-input v-model="ruleForm.idcard" placeholder="请输入身份证号" clearable style="width:460px"></el-input>
						</el-form-item>
						<div style="display: flex;align-items: center;padding: 10px;">
							<el-form >
								<div style="width: 250px;text-align: center;margin-bottom: 20px;color: #f00;">身份证正面照</div>
								<el-upload class="avatar-uploader" :action="imgApi" :show-file-list="false"
								:on-success="handleAvatarSuccess1" :before-upload="beforeAvatarUpload1">
									<img v-if="ruleForm.frontImg" :src="ruleForm.frontImg" class="avatar">
									<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								</el-upload>
							</el-form>
							<el-form style="margin-left: 40px;">
								<div style="width: 250px;text-align: center;margin-bottom: 20px;color: #f00;">身份证反面照</div>
								<el-upload class="avatar-uploader" :action="imgApi" :show-file-list="false"
								:on-success="handleAvatarSuccess2" :before-upload="beforeAvatarUpload1">
									<img v-if="ruleForm.backImg" :src="ruleForm.backImg+'?x-oss-process=image/resize,m_fixed,h_120,w_250'" class="avatar">
									<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								</el-upload>
							</el-form>
						</div>
					</el-form>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button @click="roleVisible=false">关 闭</el-button>
					<el-button type="primary" @click="roleInfoChange" style="margin-left: 30px;">确定</el-button>
				</span>
			</div>
		</el-dialog>

		<!-- 修改收货地址 -->
        <el-dialog title="修改收货地址" :visible.sync="editAddressDialog" width="650px" @close="addressClose">
            <el-form :model="editAddressForm" ref="editAddressForm" label-width="120px" style="margin-top:30px;">
                <el-form-item label="收货人 :" label-width="150px" prop="receiveName" :rules="[{required:true,message:'收货人不能为空',trigger: 'blur'}]">
                    <el-input v-model="editAddressForm.receiveName" placeholder="收货人" clearable style="width:250px" maxlength="20"></el-input>
                </el-form-item>
                <el-form-item label="收货人手机 :" label-width="150px" prop="receivePhone" :rules="[{required:true,message:'收货人手机不能为空',trigger: 'blur'}]">
                    <el-input v-model="editAddressForm.receivePhone" placeholder="收货人手机" clearable style="width:250px" maxlength="11"></el-input>
                </el-form-item>
                <el-form-item label="收货地址" prop="addressArr" label-width="150px" :rules="[{required:true,message:'请选择省市区',trigger: 'blur'}]">
                    <el-cascader separator="-" style="width: 250px;" :props="props" :options="dataList" @change="handleAddressChange"
                    v-model="editAddressForm.addressArr" />
                </el-form-item>
                <el-form-item label="详细地址 :" label-width="150px" prop="receiveDetailAddress" :rules="[{required:true,message:'详细地址不能为空',trigger: 'blur'}]">
                    <el-input v-model="editAddressForm.receiveDetailAddress" placeholder="详细地址" clearable style="width:250px" maxlength="200" type="textarea"
                    :rows="2"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editAddressDialog=false">关 闭</el-button>
                <el-button type="primary" @click="sureEditAddress()">确 认</el-button>
            </span>
        </el-dialog>
        <!-- 订单备注弹框 -->
		<el-dialog title="订单备注" :visible.sync="ordermarkDialogVisible" width="650px" @close="orderForm.orderMark = ''">
			<el-form :model="orderForm" ref="orderForm" :rules="rules">
				<el-form-item label="订单备注 :" label-width="120px" prop="orderMark">
					<el-input v-model="orderForm.orderMark" maxlength="200" type="textarea" style="width:300px;" clearable></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="ordermarkDialogVisible=false">关 闭</el-button>
				<el-button type="primary" @click="sureOrdermark('orderForm')" :loading="loading">确 认</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		orderlookDetail,
		selectChildOrderTark,
		orderRefundAll,
		ordererefundsave,
		findLogistics,
		updateUserCard,
        updateAddress,
        orderRemark
	} from '@/api/goods'
	// import axios from 'axios'
	import config from '@/config/index'
	import util from '@/utils/util.js'
    import buttonPermissions from '@/components/buttonPermissions';
	export default {
		components: {
			buttonPermissions
		},
		data() {

			return {
				OrderNo: '',
				packageDesc: [],
				groupData: [],
				orderRemark: [],
				packageRecord: [],
				expressVisible: false,
				activeTab: "1",
				backVisible: false,
				detailoading: false,
				loading: false,
				imgUrl: config.IMG_BASE,
				orderInfo: null,
				stateList: ['待付款', '待发货', '待收货', '待评价', '已完成', '已关闭', '售后中'],
				activeName: "0",
				packageId: 0,
				currentRow: null,
				backAllVisible: false, //整单退
				expressList: [],
				expressDetailList: [],
				ruleForm: {
					name: '',
					idcard: '',
					backImg:'',
					frontImg:''
                },
                orderForm:{
                    orderMark:'',   //备注弹窗内容
                },
				rules: {
					name: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur',
					}, ],
					idcard: [{
						required: true,
						message: '请输入身份证号',
						trigger: 'blur',
					}],
				},
				roleVisible: false,
				imgApi: config.UPLOAD_IMG,
				currentRowId:0,
				editAddressForm:{
					receiveName:'',
					receivePhone:'',
					receiveProvince:'',
					receiveCity:'',
					receiveArea:'',
					receiveDetailAddress:'',
					addressArr:''
				},
				editAddressDialog:false,
				dataList: [],
				props: {
					label: 'name',
					value: 'name',
					children: 'children'
				},
				expressDetail:null,
                ordermarkDialogVisible: false, //订单备注
			}
		},
		beforeMount() {
			this.OrderNo = this.$route.query.Id;
			this.getOrderdata();
		},
		methods: {

			// 获取订单数据
			async getOrderdata() {
				this.dataList = util.getAddressList();
				this.detailoading = true
				try {
					let result = await orderlookDetail({
						id: this.OrderNo
					})
					if (result.success) {
						//会员等级 ： 0 ：普卡；1 银卡；2 金卡
						if (result.data.userVO.memberLevel == 0) {
							result.data.userVO.vipValue = '普卡';
						} else if (result.data.userVO.memberLevel == 1) {
							result.data.userVO.vipValue = '银卡';
						} else if (result.data.userVO.memberLevel == 2) {
							result.data.userVO.vipValue = '金卡';
						}
						// 推手等级 1 会员卡；2 银卡；3金卡； 4 铂金卡；5 钻石卡；6 黑金卡；
						if (result.data.userVO.pusherLevel == 1) {
							result.data.userVO.pusherValue = '会员卡';
						} else if (result.data.userVO.pusherLevel == 2) {
							result.data.userVO.pusherValue = '会员卡';
						} else if (result.data.userVO.pusherLevel == 3) {
							result.data.userVO.pusherValue = '会员卡';
						} else if (result.data.userVO.pusherLevel == 4) {
							result.data.userVO.pusherValue = '铂金卡';
						} else if (result.data.userVO.pusherLevel == 5) {
							result.data.userVO.pusherValue = '钻石卡';
						} else if (result.data.userVO.pusherLevel == 6) {
							result.data.userVO.pusherValue = '黑金卡';
						}else if(result.data.userVO.pusherLevel == 0){
							result.data.userVO.pusherValue = '注册用户';
						}

						result.data.childOrderVOS.map((item, index) => {
							item.num = index + 1;
							return item;
						})
                        let freightRiskAmountSum = 0;
                        let allFreightRiskAmountSum = 0;    //所有的增值服务
                        let allDiscountAmount = 0;       //所有的优惠券
                        let allProfitDeductAmount = 0;       //所有的权益抵扣

						result.data.childOrderVOS.map(item=>{
							item.list.map(items=>{
								let money = items.unitPrice*items.quantity-items.discountAmount;
                                items.xjMoney = Number(parseFloat(money).toFixed(2));
                                freightRiskAmountSum += items.freightRiskAmount;
                                allFreightRiskAmountSum += items.freightRiskAmount;
                                allDiscountAmount += items.discountAmount;
								return items;
                            })
                            allProfitDeductAmount += item.orderDetailAmoutVO.sumProfitDeductAmount;
                            item.freightRiskAmountSum = freightRiskAmountSum;
							return item;
                        })
                        result.data.orderByVO.allFreightRiskAmountSum = allFreightRiskAmountSum;
                        result.data.orderByVO.allDiscountAmount = allDiscountAmount;
                        result.data.orderByVO.allProfitDeductAmount = allProfitDeductAmount;

                        this.orderInfo = result.data;
						if(this.orderInfo.orderByVO.userIdCardInfo){
							this.ruleForm.frontImg = this.orderInfo.orderByVO.userIdCardInfo.frontImgUrl;
							this.ruleForm.backImg = this.orderInfo.orderByVO.userIdCardInfo.reverseImgUrl;
							this.ruleForm.name = this.orderInfo.orderByVO.userIdCardInfo.realName;
							this.ruleForm.idcard = this.orderInfo.orderByVO.userIdCardInfo.idCardNo;
                        }

						this.groupData = result.data.childOrderVOS;
						var list = [];
						this.groupData.map((item, index) => {
							list.push('包裹' + (index + 1));
							return item;
						})
						this.packageDesc = list;
						if (this.groupData.length) {
							this.packageId = this.groupData[0].id;
							this.getPackageHistory();
						}
						this.orderRemark = result.data.remarks;
					}else{
						this.$message({
							showClose: true,
							type: 'error',
							message: result.alertMsg
						});
					}
				} catch (e) {
					console.log(e)
				} finally {
					this.detailoading = false
				}

			},
			expressClick(row) {
				this.currentRowId = row.id;
				this.expressList = row.logisticsList || [];
				this.expressList.map((item, index) => {
					item.label = '物流' + (index + 1);
					item.value = '' + (index + 1);
					return item;
				})
				if (this.expressList.length) {
					this.getExpressDetail(0);
				}
				this.expressVisible = true;
			},
			handleClick(tab, event) {
				console.log(tab, event);
				console.log(tab.index);
				var index = +tab.index;
				this.getExpressDetail(index);
			},
			continueBack() {
				this.backVisible = false;
				this.ordererefundInit();
			},
			submitBack(row) {
				this.currentRow = row;
				this.backVisible = true;
			},
			submitBackAll() {
				this.backAllVisible = true;
			},
			continueBackAll() {
				this.backAllVisible = false;
				this.orderBackAll();
			},
			handlePackageClick(tab, event) {
				console.log(tab, event);
				this.activeName = tab.index;
				var ids = +this.activeName;
				this.packageId = this.groupData[ids].id;
				this.getPackageHistory();
			},
			async getPackageHistory() {
				try {
					let res = await selectChildOrderTark({
						id: this.packageId
					});
					if (res.success) {
						res.data.map(item => {
							switch (item.orderStatus) {
								case 1:
									item.orderValue = "创建订单";
									break;
								case 2:
									item.orderValue = "订单支付";
									break;
								case 3:
									item.orderValue = "订单发货";
									break;
								case 4:
									item.orderValue = "订单收货";
									break;
								case 5:
									item.orderValue = "交易完成";
									break;
								default:
									item.orderValue = "创建订单";
									break;
							}
							return item;
						})
						this.packageRecord = res.data;
					} else {
						this.$message({
							showClose: true,
							type: 'error',
							message:res.alertMsg?res.alertMsg : '包裹信息获取失败，请重试'
						});
					}
				} catch (e) {
					//TODO handle the exception
				}
			},
			async ordererefundInit() {
				try {
					let res = await ordererefundsave({
						orderDetailId: this.currentRow.id
					})
					if (res.success) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '主动退款成功'
						});
						this.getOrderdata();
					} else {
						this.$message({
							showClose: true,
							type: 'error',
							message: res.alertMsg ? res.alertMsg : '退款失败'
						});
					}
				} catch (e) {
					//TODO handle the exception
				}
			},
			async orderBackAll() {
				try {
					let res = await orderRefundAll({
						mainOrderId: this.OrderNo,
						applyReason: ''
					})
					if (res.success) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '整单退款成功'
						});
						this.getOrderdata();
					} else {
						this.$message({
							showClose: true,
							type: 'error',
							message: res.alertMsg ? res.alertMsg : '退款失败'
						});
					}
				} catch (e) {
					//TODO handle the exception
				}
			},
			async getExpressDetail(index) {
				try {
					let res = await findLogistics({
						courierNo: this.expressList[index].logisticsCompanyCode,
						expressNo: this.expressList[index].expressNo,
						phone: this.orderInfo.orderByVO.receivePhone,
						bizId:this.currentRowId
					})
					if (res.success) {
						console.log(res.data)
						this.expressDetail = res.data;
						this.expressDetailList = res.data.logisticsDetailResponseList || [];
					}
				} catch (e) {
					//TODO handle the exception
				}
			},
			editRoleInfo() {
				this.roleVisible = true;
				console.log("--------",this.roleVisible)
			},
			async roleInfoChange() {
				this.$refs['ruleForm'].validate(async (valid) => {
					if (valid) {
						console.log(valid)
						if(!this.ruleForm.frontImg){
							this.$message({
								showClose: true,
								type: 'error',
								message: '请上传身份证正面照片'
							});
							return;
						}
						if(!this.ruleForm.backImg){
							this.$message({
								showClose: true,
								type: 'error',
								message: '请上传身份证反面照片'
							});
							return;
						}
						let res = await updateUserCard({
							frontImgUrl: this.ruleForm.frontImg,
							idCardNo: this.ruleForm.idcard,
							realName: this.ruleForm.name,
							reverseImgUrl: this.ruleForm.backImg,
							id:this.orderInfo.orderByVO.userIdCardInfo.id
						})
						if (res.success) {
							this.getOrderdata();
						}else{
							this.$message({
								showClose: true,
								type: 'error',
								message: res.alertMsg
							});
						}
					}
				})
			},
			handleAvatarSuccess1(res) {
				this.ruleForm.frontImg = res.data[0];
			},
			handleAvatarSuccess2(res) {
				this.ruleForm.backImg = res.data[0];
			},
			// 上传图前钩子
			beforeAvatarUpload1 (file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
				const isLt3M = file.size / 1024 / 1024 < 3;
				if (!isJPG) {
					this.$message.error('上传头像图片必须是 JPG 或者PNG格式!');
				}
				if (!isLt3M) {
					this.$message.error('图片大小请控制在3M以内!');
				}
				return isJPG && isLt3M;
            },
            // eslint-disable-next-line
			addressDialogShow(row){
				this.editAddressForm = {
					receiveName:'',
					receivePhone:'',
					receiveProvince:'',
					receiveCity:'',
					receiveArea:'',
					receiveDetailAddress:'',
					addressArr:''
				}
				this.editAddressDialog = true;
			},
			addressClose(){
				this.editAddressDialog = false;
			},
			handleAddressChange() {
				this.editAddressForm.receiveProvince = this.editAddressForm.addressArr[0]
				this.editAddressForm.receiveCity = this.editAddressForm.addressArr[1]
				this.editAddressForm.receiveArea = this.editAddressForm.addressArr[2]
				this.dataList.map(item => {
					if (item.name == this.editAddressForm.receiveProvince) {
						this.editAddressForm.receiveProvince = item.name
						item.children.map(items => {
							if (items.name == this.editAddressForm.receiveCity) {
								this.editAddressForm.receiveCity = items.name
								items.children.map(lastitems => {
									if (lastitems.name == this.editAddressForm.receiveArea) {
										this.editAddressForm.receiveArea = lastitems.name
									}
								})
							}
						})
					}
				})
			},
			sureEditAddress(){
				this.$refs['editAddressForm'].validate(async (valid) => {
					if (valid) {
						this.updateAddress();
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			async updateAddress(){
				try{
					let data = {
						orderId:this.OrderNo,
						receiveName:this.editAddressForm.receiveName,
						receivePhone:this.editAddressForm.receivePhone,
						receiveProvince:this.editAddressForm.receiveProvince,
						receiveCity:this.editAddressForm.receiveCity,
						receiveArea:this.editAddressForm.receiveArea,
						receiveDetailAddress:this.editAddressForm.receiveDetailAddress,
					};
					let res = await updateAddress(data);
					if(res.success){
						this.$message({
							type: 'success',
							message: '收货地址修改成功!'
						});
						this.editAddressDialog = false;
					}else{
						this.$message({
							type: 'error',
							message: res.alertMsg
						});
					}
				}catch(e){
					//TODO handle the exception
				}
            },
            // 确认订单备注
			sureOrdermark(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.loading = true;
						try {
							let data = {
								mainOrderId: this.OrderNo,
								remark: this.orderForm.orderMark
							}
							let result = await orderRemark(data)
							if (result.success) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.ordermarkDialogVisible = false;
								this.getOrderdata();
							}
						} catch (error) {
							console.log(error)
						} finally {
							this.loading = false;
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});

			},
		}
	}
</script>

<style lang="less" >
	.orderDetailpage {
		width: 100%;
		padding: 30px;
		background: #fff;
		font-size: 14px;
		color: #333;

		.mgTop {
			margin-top: 10px;
		}

		.dialog-footer {
			margin-top: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
		}
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 250px;
		height: 140px;
		line-height: 140px;
		text-align: center;
	}

	.avatar {
		width: 250px;
		height: 140px;
		display: block;
	}
</style>
